<template>
  <v-container
    fluid
    class="pa-0"
  >
    <div
      class="section1 small"
    >
      <v-row>
        <v-col
          cols="12"
          sm="8"
          md="6"
          lg="4"
          class="mt-16"
        >
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ml-16 mt-16">
          <div
            class="travel-text mt-0"
          >
            Who should buy <br> home insurance?
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="section2">
      <v-row>
        <v-col class="mt-16">
          <v-spacer class="mt-16"></v-spacer>
        </v-col>
      </v-row>
    </div>
    <div class="section3 pb-12">
      <v-row>
        <v-col cols="6">
          <div class="insurance mt-10 ml-16">
            Home Insurance
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-16">
        <v-col cols="9">
          <div class="sec3-sub mt-10 ml-16 pb-16">
            All homeowners should consider home insurance to protect them in the <br> event damage is caused to their home. <br> A homeowners policy should be purchased to make an owner "whole" following damage or destruction to their home or in the event of a burglary!
          </div>
        </v-col>
      </v-row>
      <v-img
        src="/img/home-icon.png"
        class="home-icon-small"
      ></v-img>
      <v-img
        src="/img/home-underline.png"
        class="home-underline-small"
      ></v-img>
    </div>
    <div class="section4 mt-n16 pb-16">
      <v-row class="pb-12">
        <v-col
          cols="7"
          class="mt-16 pr-n8"
        >
          <v-img
            src="/img/model-home.png"
            class="travel-fam"
          ></v-img>
        </v-col>
        <v-col
          cols="5"
          class=" mt-4 ml-n8"
          style="z-index: 5;"
        >
          <div
            class="free-look pt-4 mt-0"
          >
            Things to consider when you buy <br> a homeowner’s policy. <br> <br>
          </div>
          <v-row>
            <v-col cols="1">
              <v-icon
                left
                x-large
              >
                mdi-check
              </v-icon>
            </v-col>
            <v-col cols="10">
              <div class="free-look-txt">
                Rebuilding costs which often are higher than a home’s market value.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-icon
                left
                x-large
              >
                mdi-check
              </v-icon>
            </v-col>
            <v-col cols="10">
              <div class="free-look-txt">
                Replacement costs of the contents in the home VS their depreciated amount (or the value if you were to sell them used).
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-icon
                left
                x-large
              >
                mdi-check
              </v-icon>
            </v-col>
            <v-col cols="10">
              <div class="free-look-txt">
                Special coverage unique to your situation. For example if you have valuable art or jewelry you would want to examine the base amount of coverage in the policy and modify it according to your needs.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <v-icon
                left
                x-large
              >
                mdi-check
              </v-icon>
            </v-col>
            <v-col cols="10">
              <div class="free-look-txt">
                Review the deductible and determine a happy balance between a higher deductible and the price of the coverage.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="text-center">
          <div class="main-cvg-txt pt-16 mt-16">
            Main coverages offered through the policy
          </div>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="mt-n2"
      >
        <v-col
          cols="11"
        >
          <v-row justify="center mt-8 mb-0">
            <v-col
              cols="4"
              class="d-flex justify-center mt-16"
            >
              <v-img
                src="/img/dwelling-badge.png"
                height="120px"
                max-width="116px"
              ></v-img>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center mt-16"
            >
              <v-img
                src="/img/otherstructure-badge.png"
                height="109px"
                max-width="127px"
              ></v-img>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center mt-16"
            >
              <v-img
                src="/img/personalproperty-badge.png"
                height="108px"
                max-width="133px"
              ></v-img>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-subtxt">
                Dwelling
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-subtxt">
                Other Structures
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-subtxt">
                Personal Property
              </div>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-info">
                Pays to repair or rebuild your home and attached structures, up to chosen coverage limits, if it's damaged or destroyed by fire or storms.
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-info">
                Similar to Dwelling Coverage, but for unattached structures.
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-info">
                Pays for damage to or theft of most personal items, including appliances, furniture, electronics and clothing.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="mt-n2"
      >
        <v-col
          cols="11"
        >
          <v-row justify="center mt-8 mb-0">
            <v-col
              cols="4"
              class="d-flex justify-center mt-16"
            >
              <v-img
                src="/img/medicalpayment-badge.png"
                height="120px"
                max-width="119px"
              ></v-img>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center mt-16"
            >
              <v-img
                src="/img/liability-badge.png"
                height="119px"
                max-width="109px"
              ></v-img>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center mt-16"
            >
              <v-img
                src="/img/lossofuse-badge.png"
                height="119px"
                max-width="113px"
              ></v-img>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-subtxt">
                Medical Payments
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-subtxt">
                Personal Liability
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-subtxt">
                Loss Of Use
              </div>
            </v-col>
          </v-row>
          <v-col>
            <v-row justify="center">
              <v-col
                cols="4"
                class="d-flex justify-center"
              >
                <div class="sec4-info">
                  Pays for medical expenses for bodily injury to guests while on your property regardless of fault.
                </div>
              </v-col>
              <v-col
                cols="4"
                class="d-flex justify-center"
              >
                <div class="sec4-info">
                  Pays for expenses associated with bodily injury or property damage to others due to negligence on your part both on or off your property.
                </div>
              </v-col>
              <v-col
                cols="4"
                class="d-flex justify-center"
              >
                <div class="sec4-info">
                  Pays the bills or expenses you have as a result of losing the use or your residence.
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="extras-title pt-16 pb-16">
            Following items can also be added to your policy
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="5"
        >
          <div
            class="d-inline-flex align-center"
            style="width: 100%;"
          >
            <v-img
              src="/img/add-badge.png"
              max-width="87px"
              height="87px"
            ></v-img> <div
              class="extras-text d-flex align-center ml-n4"
            >
              <span class="pl-6">Animal Liability</span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="5"
        >
          <div
            class="d-inline-flex align-center"
            style="width: 100%;"
          >
            <v-img
              src="/img/add-badge.png"
              max-width="87px"
              height="87px"
            ></v-img> <div
              class="extras-text d-flex align-center ml-n4"
            >
              <span class="pl-6">Scheduled Personal Property</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="5"
          class="d-flex justify-center"
        >
          <div
            class="d-inline-flex align-center"
            style="width: 100%;"
          >
            <v-img
              src="/img/add-badge.png"
              max-width="87px"
              height="87px"
            ></v-img> <div
              class="extras-text d-flex align-center ml-n4"
            >
              <span class="pl-6">Identity Theft Expense Recoupment</span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="5"
          class="d-flex justify-center"
        >
          <div
            class="d-inline-flex align-center"
            style="width: 100%;"
          >
            <v-img
              src="/img/add-badge.png"
              max-width="87px"
              height="87px"
            ></v-img> <div
              class="extras-text d-flex align-center ml-n4"
            >
              <span class="pl-6">Special Personal Property</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center pb-16">
        <v-col
          cols="5"
          class="d-flex justify-center pb-16"
        >
          <div
            class="d-inline-flex align-center"
            style="width: 100%;"
          >
            <v-img
              src="/img/add-badge.png"
              max-width="87px"
              height="87px"
            ></v-img> <div
              class="extras-text d-flex align-center ml-n4"
            >
              <span class="pl-6">Personal Injury</span>
            </div>
          </div>
        </v-col>
        <v-col
          cols="5"
          class="d-flex justify-center pb-16"
        >
          <div
            class="d-inline-flex align-center"
            style="width: 100%;"
          >
            <v-img
              src="/img/add-badge.png"
              max-width="87px"
              height="87px"
            ></v-img> <div
              class="extras-text d-flex align-center ml-n4"
            >
              <span class="pl-6">Water Back-Up</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="section5 mb-n16 ">
        <v-row
          justify="center"
          class="pt-16"
        >
          <v-col
            cols="8"
            class="d-flex justify-center pt-16"
          >
            <div class="section5-txt pt-12">
              <span class="font-weight-bold">Blanket offers Home insurance products through</span>  <br> Fidelity National Property and Casualty Insurance Group, a branch of FNF, the country's largest provider of commercial and residential title and mortgage services.
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
.section1{
  position: relative;
  width: 100%;
  background: url("../../../../public/img/home-learnmore-background.png") no-repeat;
  background-size: cover;
}

.section2 {
    background-color: #FFFFFF;
}

.section3 {
    background-color: #DEECF1;
}

.section4 {
    background-color: #FFFFFF;
}

.covid-coverage-small {
    position: absolute;
    max-width: 300px;
    height: auto;
    object-fit: scale-down;
    right: 0;
    top: 75rem;
}

.covid-coverage-large {
    position: absolute;
    max-width: 300px;
    height: auto;
    object-fit: scale-down;
    right: 0;
    top: 90rem;
}

.free-look-small {
    position: absolute;
    max-width: 300px;
    height: auto;
    object-fit: scale-down;
    right: 16rem;
    top: 75rem;
}

.free-look-large {
    position: absolute;
    max-width: 300px;
    height: auto;
    object-fit: scale-down;
    right: 16rem;
    top: 90rem;
}

.sec3-sub {
font-size: 28px;
line-height: 132.19%;
color: #757575;
}

.travel-text {
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
letter-spacing: -0.005em;

color: #616161;
}

.insurance {
font-size: 60px;
line-height: 70px;
letter-spacing: -0.005em;
color: #757575;
}

.ins-card {
    background-color: #EEEEEE;
    width: 425px;
    height: 252px;
}

.learn {
  border: 1px solid #00A1B7 !important;
}

.sec4-txt {
    font-size: 55px;
line-height: 64px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
}

.plan-img {
    max-width: 155px;
    height: auto;
    object-fit: scale-down;
}

.coverage-img {
    max-width: 130px;
    height: auto;
    object-fit: scale-down;
}

.payout-img {
    max-width: 145px;
    height: auto;
    object-fit: scale-down;
}

.customercare-img {
    max-width: 136px;
    height: 119px;
    object-fit: scale-down;
}
.sec4-subtxt {
font-weight: 500;
font-size: 24px;
line-height: 32px;
text-align: center;
text-transform: capitalize;
color: #212121;
}

.sec4-info {
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    font-feature-settings: 'liga' off;
    color: #757575;
}

.section5 {
    height: 500px;
    width: 100%;
    background: rgba(216, 253, 215, 0.5);
}

.section5-txt{
font-size: 26px;
line-height: 42px;
text-align: center;
color: #757575;
}

.small {
    height: 700px;
}

.large {
    height: 900px;
}

.travel-fam {
    max-width: 1114px;
    height: auto;
    object-fit: scale-down;
}

.free-look {
    font-weight: 500;
font-size: 36px;
line-height: 42px;
color: #757575;
}

.free-look-txt {
font-size: 24px;
line-height: 33px;
font-feature-settings: 'liga' off;
color: #757575;
}

.btm-txt {
font-size: 20px;
line-height: 26px;
text-align: center;
color: #757575;
}

.home-icon-small {
    position: absolute;
    max-width: 175px;
    height: auto;
    object-fit: scale-down;
    right: 5rem;
    top: 75rem;
}
.home-icon-large {
    position: absolute;
    max-width: 175px;
    height: auto;
    object-fit: scale-down;
    right: 5rem;
    top: 87rem;
}
.home-underline-small {
    position: absolute;
    height: 4px;
    object-fit: scale-down;
    right: 0;
    top: 87.2rem;
}
.home-underline-large {
    position: absolute;
    height: 4px;
    object-fit: scale-down;
    right: 0;
    top: 99.2rem;
}

.main-cvg-txt {
    font-size: 55px;
    line-height: 64px;
    text-align: center;
    letter-spacing: -0.005em;
    color: #757575;
}

.extras-title {
font-size: 60px;
line-height: 70px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
}

.extras-text {
    font-weight: 700;
    font-size: 26px;
    line-height: 157.69%;
    color: #212121;
    background-color: #EDF8FA;
    height: 64px;
    width: 100%;
}
</style>
